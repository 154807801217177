import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Chip,
  TablePagination,
  Select,
  InputLabel,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import AdminLayout from "../Layout/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, getUsersCounts, saveUserStatus } from "../../API/Reducer/userReducer";
import { formatUTCDate } from "../../MIS/Global";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserStatsCard from "./UserStatsCard";
import { getCurrentMonthDate, getCurrentWeekDate, getNextMonthDate } from "../../MIS/Globdata";

const AdminUserList = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const [searchInput, setSearchInput] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  
  const open = Boolean(anchorEl);
  const [type, setType] = React.useState(0);
  const [alignment, setAlignment] = useState(null);
  
  const [whr, setWhr] = useState({ urole: 0, ustatus: type });
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
    let dateData={};
    if(newAlignment==="w"){
      dateData=getCurrentWeekDate(new Date());
      setWhr({...whr, urole: 0, ustatus: 0,fdate:dateData?.start,tdate:dateData?.end,srch:'' });
     }else if(newAlignment==="m"){
     dateData=getCurrentMonthDate(new Date());
     setWhr({...whr, urole: 0, ustatus: 0,fdate:dateData?.start,tdate:dateData?.end,srch:'' });
    }else if(newAlignment==="nm"){
     dateData=getNextMonthDate(new Date());
     setWhr({...whr, urole: 0, ustatus: 0,fdate:dateData?.start,tdate:dateData?.end,srch:'' });
    }else{     
     setWhr({urole: 0, ustatus: type,srch:''});
    }
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
    setWhr({urole: 0, ustatus: event.target.value});
 
  };
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const getUserList = () => {
     dispatch(getUsers(whr));
     setSearchInput('');
  };

  useEffect(() => {    
    getUserList();
  }, [whr]);

  useEffect(() => {      
    dispatch(getUsersCounts())
 },[])

 
 

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredData = userData.filter((element) => {
    const searchStr = searchInput.toLowerCase();
    return (
      String(element.uname).toLowerCase().includes(searchStr) ||
      String(element.cmpname).toLowerCase().includes(searchStr) ||
      String(element.umob).toLowerCase().includes(searchStr) ||
      String(element.uemail).toLowerCase().includes(searchStr)
    );
  });

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  return (
    <AdminLayout>
      <Grid container p={1} spacing={1}>
      <Grid item md={12} xs={12}>
            <div style={{padding:"10px"}}>
             <h2>User List</h2>
            </div>
          </Grid>
        <Grid item xs={12} md={12}>
          <Paper sx={{ padding: "10px" }}>
            <Grid container>
              <Grid item xs={12} md={8} sx={{display:"flex",gap:"10px"}}>
                <FormControl>
                  <InputLabel>User Type</InputLabel>
                  <Select
                    size="small"
                    value={type}
                    label="User Type"
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={0}>Active</MenuItem>
                    <MenuItem value={1}>Expired</MenuItem>
                    <MenuItem value={2}>Block</MenuItem>
                  </Select>
                </FormControl>
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  sx={{
                    display:type===1?"block":"none"
                  }}
                >
                  <ToggleButton value="w">Week</ToggleButton>
                  <ToggleButton value="m">Month</ToggleButton>
                  <ToggleButton value="nm">Next Month</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Search"
                  size="small"
                  variant="standard"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" sx={{cursor:"pointer"}} onClick={(e)=>{
                         setWhr({...whr,srch:searchInput});
                        }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearchInputChange}
                  value={searchInput}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
         <Paper elevation={4}>
          <UserStatsCard />
         </Paper>
        </Grid>

        <Grid item xs={12} md={9}>
          <TableContainer component={Paper}>
            <Table size="small" stickyHeader sx={{ maxHeight: 440 }}>
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">Company</TableCell>
                  <TableCell align="right">Users</TableCell>
                  <TableCell align="right" width="150">Exp. Date</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row, index) => 
                {
                  let bgcolor="#fff";
                   if(index % 2==0){bgcolor="aliceblue";}
                  return(
                  <React.Fragment key={row.id}>
                    <TableRow sx={{backgroundColor:bgcolor}}>
                      {/* <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleRowClick(index)}
                        >
                          {openRow === index ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {row?.uname}-{row?.umob}
                      </TableCell>
                      <TableCell component="th" scope="row" width="150">
                        {row?.uemail}
                      </TableCell>
                      <TableCell align="right">{row?.cmpname}</TableCell>
                      <TableCell align="right">{row?.user_count || 0}</TableCell>
                      <TableCell align="right">
                        {formatUTCDate(new Date(row?.expdate), "d3my")}
                      </TableCell>
                      <TableCell align="right">
                        <Chip
                          label={
                            row?.ustatus === 0
                              ? "Active"
                              : row?.ustatus === 1
                              ? "Expired"
                              : "Block"
                          }
                          color={row?.ustatus === 0 ? "success" : "error"}
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right" sx={{ cursor: "pointer" }}>
                        <MoreVertIcon
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={openRow === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <h6>Details</h6>
                            <p>{row?.details?.description}</p>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow> */}
                  </React.Fragment>
                )})}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100, 200, 250, 300, 500]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose("None")}>
        <MenuItem
          onClick={() => {
            handleClose("Active");
            dispatch(saveUserStatus({ id: selectedRowId, ustatus: 0 }));
            getUserList();
          }}
        >
          Active
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose("Block");
            dispatch(saveUserStatus({ id: selectedRowId, ustatus: 2 }));
            getUserList();
          }}
        >
          Block
        </MenuItem>
      </Menu>
    </AdminLayout>
  );
};

export default AdminUserList;
